/** @define wsmenu; weak */
.wsmenu--fixed {
  max-width: 1680px;
  margin: 0 auto;
}
.wsmenu--fixed a {
  padding: 10px 20px;
  line-height: 1.2;
}
.wsmenu--fixed a.active {
  color: #ffffff;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
}
.wsmenu--fixed a.active span {
  background-size: 100% 2px, auto;
}
.wsmenu--fixed .nav__dropdown-toggle {
  line-height: 1;
}
.wsmenu--fixed .nav button {
  padding: 10px 20px;
  color: #ffffff;
}
.wsmenu__list {
  position: absolute;
  z-index: 9;
  width: 100%;
  white-space: nowrap;
}
@media only screen and (max-width: 999px) {
  .wsmenu__list {
    background-color: rgba(245, 154, 0, 0.9);
  }
}
@media only screen and (max-width: 899px) {
  .wsmenu__list {
    border: 1px solid #f59a00;
  }
}
@media only screen and (min-width: 1000px) {
  .wsmenu__list {
    position: absolute;
    top: 60px;
    right: 5px;
    width: auto;
  }
}
@media only screen and (min-width: 1450px) {
  .wsmenu__list {
    position: absolute;
    top: 12px;
    right: 322px;
    width: max-content;
  }
}
@media only screen and (min-width: 1500px) {
  .wsmenu__list {
    right: 320px;
  }
}
.wsmenu__list li {
  display: inline-block;
}
@media only screen and (min-width: 1040px) {
  .wsmenu__list li:last-child a {
    background-color: #f59a00;
    border-radius: 5px;
    box-shadow: 0 -10px 25px -19px #f5b300;
  }
}
.wsmenu__list a {
  display: inline-block;
  padding: 14px 20px;
  color: #ffffff;
  font-size: 16px;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
}
@media only screen and (max-width: 1039px) {
  .wsmenu__list a {
    text-align: center;
  }
}
@media only screen and (min-width: 1500px) {
  .wsmenu__list a {
    text-transform: uppercase;
  }
}
.wsmenu__list a span {
  padding-bottom: 10px;
  background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 0 2px, auto;
  transition: all 0.2s ease-out;
}
.wsmenu__list a:hover {
  text-decoration: none;
}
.wsmenu__list a:hover span {
  background-size: 60% 2px, auto;
}
.wsmenu__list a.active {
  color: #000000;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
}
.wsmenu__list a.active span {
  background-size: 100% 2px, auto;
}
.wsmenu__list--slideup {
  transform: translateY(-100px);
  transition: transform 0.5s ease-out;
}
.wsmenu__list--slidedown {
  transform: translateY(0);
  transition: transform 0.5s ease-out;
}
/*.wsmenu__list--up {
  position: unset;
  top: unset;
  left: unset;
  z-index: 999;

  display: block;
  width: unset !important;
  height: unset;

  border: 1px solid red;

  line-height: unset;
  text-align: unset;

  background-color: unset;
  box-shadow: unset;
}*/
.wsmenu__list--down {
  position: fixed !important;
  top: 0 !important;
  left: 0;
  z-index: 99;
  display: block;
  width: 100% !important;
  text-align: center;
  background-color: #000000;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media only screen and (max-width: 899px) {
  .wsmenu__list--down {
    top: 70px !important;
    border: none;
  }
}
/*# sourceMappingURL=css/menu.css.map */